<template>
    <div class="col-12">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-4 m-2">
          <h4>Responsável</h4>
        </div>
      </div>
      <div class="row">
        <div
          v-if="$store.state.fastPessoasResponsaveis.loading"
          class="col-12 text-center"
        >
          <b-spinner
            type="grow"
            label="Spinning"
          />
        </div>
        <div
          v-else
          class="col-12"
        >
          <div class="row">
            <div
              class="col-sm-12 col-md-12 col-lg-12 text-right"
            >
              <button
                class="btn btn-sm btn-primary"
                @click.prevent="
                  showModal(
                    'modalCadastrarAlunoResponsavel'
                  )
                "
              >
                <small>+ Responsável</small>
              </button>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12 table-responsive">
              <table class="table table-sm table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th>Nome do responsável</th>
                    <th class="text-center">
                      Parentesco
                    </th>
                    <th class="text-center">
                      Responsável financeiro
                    </th>
                    <th class="text-center">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody
                  v-if="
                    $store.state.fastPessoasResponsaveis.lista.length
                  "
                >
                  <tr
                    v-for="(
                      r, index
                    ) in $store.state.fastPessoasResponsaveis.lista"
                    :key="index"
                  >
                    <td>
                      {{ r.nome_responsavel }}
                    </td>
                    <td class="text-center">
                      {{ r.parentesco }}
                    </td>
                    <td class="text-center">
                      <button
                        v-if="r.responsavel_financeiro"
                        class="btn btn-sm btn-success pt-0 pb-0"
                      >
                        <small>Sim</small>
                      </button>
                      <button
                        v-else
                        class="btn btn-sm btn-danger pt-0 pb-0"
                      >
                        <small>Não</small>
                      </button>
                    </td>
                    <td class="text-center">
                      <button
                        class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                        @click="
                          exibeModalEditarAlunoResponsavel(
                            r
                          )
                        "
                      >
                        <small
                          class="text-white"
                        >Editar</small>
                      </button>
                      <button
                        class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                        @click="
                          exibeModalExcluirAlunoResponsavel(
                            r
                          )
                        "
                      >
                        <small
                          class="text-white"
                        >Excluir</small>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      colspan="4"
                      class="text-center"
                    >
                      <span>Nenhum responsável cadastrado</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- Modals -->
      <modal
      name="modalCadastrarAlunoResponsavel"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Cadastrar responsável</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCadastrarAlunoResponsavel')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
                      <label>Nome do responsável <span class="text-danger">*</span></label>
                      <div class="form-group">
                        <input
                          v-model="$store.state.fastPessoaResponsavel.nome_responsavel"
                          class="form-control"
                          type="text"
                          maxlength="100"
                          autocomplete="no"
                        >
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
                      <label>Email do responsável <span class="text-danger">*</span></label>
                      <div class="form-group">
                        <input
                          v-model="$store.state.fastPessoaResponsavel.email_responsavel"
                          class="form-control"
                          type="email"
                          autocomplete="no"
                        >
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
                      <label>Parentesco</label>
                      <div class="form-group">
                        <input
                          v-model="$store.state.fastPessoaResponsavel.parentesco"
                          class="form-control"
                          type="text"
                          maxlength="10"
                          list="parentescos"
                          id="parentesco"
                        >
                        <datalist id="parentescos">
                          <option value="Pai"/>
                          <option value="Mãe"/>
                          <option value="Avô"/>
                          <option value="Avó"/>
                          <option value="Tio"/>
                          <option value="Tia"/>                    
                        </datalist>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
                      <label>Telefone <span class="text-danger">*</span></label>
                      <div class="form-group">
                        <input
                          v-model="$store.state.fastPessoaResponsavel.celular"
                          v-mask="[
                            '(##) ####-####',
                            '(##) #####-####',
                          ]"
                          class="form-control"
                          type="text"
                          maxlength="20"
                          autocomplete="no"
                        >
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
                      <label>Telefone comercial</label>
                      <div class="form-group">
                        <input
                          v-model="$store.state.fastPessoaResponsavel.fone_comercial"
                          v-mask="[
                            '(##) ####-####',
                            '(##) #####-####',
                          ]"
                          class="form-control"
                          type="text"
                          maxlength="20"
                          autocomplete="no"
                        >
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4">
                      <label>Data de nascimento <span class="text-danger">*</span></label>
                      <div class="form-group">
                        <input
                          v-model="
                            $store.state.fastPessoaResponsavel.datanascimento_abertura
                          "
                          class="form-control"
                          type="date"
                          autocomplete="no"
                        >
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4">
                      <label>CPF <span class="text-danger">*</span></label>
                      <div class="form-group">
                        <input
                          v-model="$store.state.fastPessoaResponsavel.cpf_cnpj"
                          v-mask="['###.###.###-##', '##.###.###/####-##']"
                          class="form-control"
                          type="text"
                          autocomplete="no"
                        >
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4">
                      <label>Sexo</label>
                      <div class="form-group">
                        <select
                          v-model="$store.state.fastPessoaResponsavel.sexo"
                          class="form-control"
                        >
                          <option value="">
                            Indefinido
                          </option>
                          <option value="M">
                            Masculino
                          </option>
                          <option value="F">
                            Feminino
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
                      <div class="fd-app-curso-aulas mt-0">
                        <div class="fd-app-curso-aulas-list mt-0">
                          <div
                            class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0"
                          >
                            <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                              <div class="col-12 text-center mb-1">
                                <span>Responsável financeiro</span>
                              </div>
                              <div class="col-12 text-center">
                                <label class="switch">
                                  <input
                                    v-model="
                                      $store.state.fastPessoaResponsavel.responsavel_financeiro
                                    "
                                    type="checkbox"
                                  >
                                  <span class="slider round" />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                  <h6>Endereço</h6>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label>CEP <span class="text-danger">*</span></label>
                  <div class="form-group">
                    <input
                      v-model="$store.state.fastPessoaResponsavel.cep"
                      v-mask="'#####-###'"
                      type="text"
                      class="form-control"
                      @keyup="buscaCEPResponsavel('novo')"
                      autocomplete="no"
                    >
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label>Endereço <span class="text-danger">*</span></label>
                  <div class="form-group">
                    <input
                      v-model="$store.state.fastPessoaResponsavel.logradouro"
                      type="text"
                      class="form-control"
                      maxlength="100"
                      autocomplete="no"
                    >
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label>Número <span class="text-danger">*</span></label>
                  <div class="form-group">
                    <input
                      v-model="$store.state.fastPessoaResponsavel.numero"
                      type="text"
                      class="form-control"
                      maxlength="10"
                      autocomplete="no"
                    >
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label>Complemento</label>
                  <div class="form-group">
                    <input
                      v-model="$store.state.fastPessoaResponsavel.complemento"
                      type="text"
                      class="form-control"
                      autocomplete="no"
                    >
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label>Bairro <span class="text-danger">*</span></label>
                  <div class="form-group">
                    <input
                      v-model="$store.state.fastPessoaResponsavel.bairro"
                      type="text"
                      class="form-control"
                      autocomplete="no"
                    >
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label>Cidade <span class="text-danger">*</span></label>
                  <div class="form-group">
                    <input
                      v-model="$store.state.fastPessoaResponsavel.cidade"
                      type="text"
                      class="form-control"
                      autocomplete="no"
                    >
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label>Estado <span class="text-danger">*</span></label>
                  <div class="form-group">
                    <input
                      v-model="$store.state.fastPessoaResponsavel.uf"
                      type="text"
                      class="form-control"
                      maxlength="2"
                      autocomplete="no"
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group mb-0">
                    <button
                      href="#"
                      type="button"
                      class="btn btn-primary"
                      @click.prevent="cadastraAlunoResponsavel()"
                    >
                      Cadastrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarAlunoResponsavel"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar responsável</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarAlunoResponsavel')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Nome do responsável</label>
              <div class="form-group">
                <input
                  v-model="
                    $store.state.fastPessoaResponsavelEditar.nome_responsavel
                  "
                  class="form-control"
                  type="text"
                  maxlength="100"
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Email do responsável</label>
              <div class="form-group">
                <input
                  v-model="
                    $store.state.fastPessoaResponsavelEditar.email_responsavel
                  "
                  class="form-control"
                  type="email"
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Parentesco</label>
              <div class="form-group">
                <input
                  v-model="$store.state.fastPessoaResponsavelEditar.parentesco"
                  class="form-control"
                  type="text"
                  maxlength="10"
                  list="parentescos"
                  id="parentesco"
                >
                <datalist id="parentescos">
                  <option value="Pai"/>
                  <option value="Mãe"/>
                  <option value="Avô"/>
                  <option value="Avó"/>
                  <option value="Tio"/>
                  <option value="Tia"/>                    
                </datalist>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Celular</label>
              <div class="form-group">
                <input
                  v-model="$store.state.fastPessoaResponsavelEditar.celular"
                  v-mask="[
                            '(##) ####-####',
                            '(##) #####-####',
                          ]"
                  class="form-control"
                  type="text"
                  maxlength="20"
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Fone comercial</label>
              <div class="form-group">
                <input
                  v-model="$store.state.fastPessoaResponsavelEditar.fone_comercial"
                  v-mask="[
                            '(##) ####-####',
                            '(##) #####-####',
                          ]"
                  class="form-control"
                  type="text"
                  maxlength="20"
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>Data de nascimento <span class="text-danger">*</span></label>
              <div class="form-group">
                <input
                  v-model="
                    $store.state.fastPessoaResponsavelEditar.datanascimento_abertura
                  "
                  class="form-control"
                  type="date"
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>CPF</label>
              <div class="form-group">
                <input
                  v-model="$store.state.fastPessoaResponsavelEditar.cpf_cnpj"
                  v-mask="['###.###.###-##', '##.###.###/####-##']"
                  class="form-control"
                  type="text"
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>Sexo</label>
              <div class="form-group">
                <select
                  v-model="$store.state.fastPessoaResponsavelEditar.sexo"
                  class="form-control"
                >
                  <option value="">
                    Indefinido
                  </option>
                  <option value="M">
                    Masculino
                  </option>
                  <option value="F">
                    Feminino
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div
                    class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0"
                  >
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="col-12 text-center mb-1">
                        <span>Responsável financeiro</span>
                      </div>
                      <div class="col-12 text-center">
                        <label class="switch">
                          <input
                            v-model="
                              $store.state.fastPessoaResponsavelEditar.responsavel_financeiro
                            "
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <h6>Endereço</h6>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>CEP</label>
              <div class="form-group">
                <input
                  v-model="$store.state.fastPessoaResponsavelEditar.cep"
                  v-mask="'#####-###'"
                  type="text"
                  class="form-control"
                  @keyup="buscaCEPResponsavel('editar')"
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>Endereço</label>
              <div class="form-group">
                <input
                  id="logradouro"
                  v-model="$store.state.fastPessoaResponsavelEditar.logradouro"
                  type="text"
                  class="form-control"
                  maxlength="100"
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>Número</label>
              <div class="form-group">
                <input
                  v-model="$store.state.fastPessoaResponsavelEditar.numero"
                  type="text"
                  class="form-control"
                  maxlength="10"
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>Complemento</label>
              <div class="form-group">
                <input
                  v-model="$store.state.fastPessoaResponsavelEditar.complemento"
                  type="text"
                  class="form-control"
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>Bairro</label>
              <div class="form-group">
                <input
                  v-model="$store.state.fastPessoaResponsavelEditar.bairro"
                  type="text"
                  class="form-control"
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>Cidade</label>
              <div class="form-group">
                <input
                  v-model="$store.state.fastPessoaResponsavelEditar.cidade"
                  type="text"
                  class="form-control"
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>Estado</label>
              <div class="form-group">
                <input
                  v-model="$store.state.fastPessoaResponsavelEditar.uf"
                  type="text"
                  class="form-control"
                  maxlength="2"
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click.prevent="atualizaAlunoResponsavel()"
                >
                  Salvar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirAlunoResponsavel"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir responsável?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirAlunoResponsavel')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirAlunoResponsavel()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    </div>    
</template>
<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
    name: "FastCadastroPessoaResponsavel",
    props: {
        readonly: {
            type: Boolean,
            required: false,
        },
        id_plataforma: {
          type: Number,
          required: true,
        },
        id_pessoa_aluno: {
          type: Number,
          required: true,
        }
    },
    
    mixins: [methods],
    data: function () {
        return {
          modalWidthSm: this.isMobile() ? "80%" : "30%",
          modalWidthMd: this.isMobile() ? "90%" : "50%",
          modalWidthLg: this.isMobile() ? "100%" : "90%",
        }        
    },
    mounted: function () {         
      if (!this.id_pessoa_aluno || !this.id_plataforma) this.exibeToasty("Plataforma não identificada", "error")   
      else
        this.getFastPlataformaAlunoResponsavel(this.id_plataforma, this.id_pessoa_aluno)
      
    },
    methods: {
      async cadastraAlunoResponsavel() {
        this.getFastHoraServidor()
        // Validação
        .then(() => {
          let erros = [];
          if (!this.$store.state.fastPessoaResponsavel.email_responsavel)
            erros.push("O email é obrigatório");
          if (!this.$store.state.fastPessoaResponsavel.celular)
            erros.push("O telefone é obrigatório");
          if (!this.$store.state.fastPessoaResponsavel.nome_responsavel)
            erros.push("O nome é obrigatório");
          else if (
            this.$store.state.fastPessoaResponsavel.nome_responsavel.split(" ").length <
            2
          )
            erros.push("O sobrenome é obrigatório");
          if (!this.$store.state.fastPessoaResponsavel.cpf_cnpj)
            erros.push("O CPF/CPNJ é obrigatório");
          if (!this.$store.state.fastPessoaResponsavel.uf)
            erros.push("O estado é obrigatório");
          if (!this.$store.state.fastPessoaResponsavel.cep)
            erros.push("O cep é obrigatório");
          if (!this.$store.state.fastPessoaResponsavel.logradouro)
            erros.push("O endereço é obrigatório");
          if (!this.$store.state.fastPessoaResponsavel.bairro)
            erros.push("O bairro é obrigatório");
          if (!this.$store.state.fastPessoaResponsavel.cidade)
            erros.push("O cidade é obrigatório");
          if (!this.$store.state.fastPessoaResponsavel.numero)
            erros.push("O número é obrigatório");
          if (!this.$store.state.fastPessoaResponsavel.datanascimento_abertura)
            erros.push("A data de nascimento é obrigatória");

          // if (this.$store.state.fastPessoaResponsavel.celular) {
          //   if (!this.validaTelefoneFormatado(this.$store.state.fastPessoaResponsavel.celular)) erros.push("O telefone está no formato incorreto")
          // } 
          // if (this.$store.state.fastPessoaResponsavel.fone_comercial) {
          //   if (!this.validaTelefoneFormatado(this.$store.state.fastPessoaResponsavel.fone_comercial)) erros.push("O telefone comercial está no formato incorreto")
          // } 
          if (this.$store.state.fastPessoaResponsavel.datanascimento_abertura) {
            let objDataNasc = new Date(`${this.$store.state.fastPessoaResponsavel.datanascimento_abertura}T00:00:00`)
            let cincoAnosAtras = new Date(this.$store.state.fastDataAtualServidor.setFullYear(this.$store.state.fastDataAtualServidor.getFullYear() - 5))
            if (objDataNasc > cincoAnosAtras) erros.push("A data de nascimento deve ser superior a 5 anos atrás")
          }
          if (this.$store.state.fastPessoaResponsavel.cpf_cnpj) {
            if (!this.validaCpfCnpj(this.$store.state.fastPessoaResponsavel.cpf_cnpj)) erros.push("CPF/CNPJ inválido")
          }      
          if (this.$store.state.fastPessoaResponsavel.cep) {
            if (!/^[0-9]{8}$/.test(this.$store.state.fastPessoaResponsavel.cep.replace("-", ""))) erros.push("CEP inválido")
          }   

          return erros
        }).then(erros => {        
          if (erros.length) {
            erros.forEach(e =>  this.exibeToasty(e, "error"))
          } else { 
            this.$store.state.fastCarregando = true
            this.promiseGetFastApi("api/fast_plataforma_usuario/usuario_check", `email=${this.$store.state.fastPessoaResponsavel.email_responsavel}&id_plataforma=${this.id_plataforma}`).then(res => {
              const retorno = JSON.parse(res)
              if (retorno.error){
                this.exibeToasty(retorno.description, "error")
                this.$store.state.fastCarregando = false
              } else if (retorno.code == "email_on_plataform") {
                this.exibeToasty("Não é possível cadastrar pois o usuário já está associado à plataforma", "error")
                this.$store.state.fastCarregando = false
              } else {
                const modelCadastroUsuarioResponsavel = {
                  fast_pessoa: {
                    nome_razao: this.$store.state.fastPessoaResponsavel.nome_responsavel,
                    cpf_cnpj: this.$store.state.fastPessoaResponsavel.cpf_cnpj,
                    datanascimento_abertura: this.$store.state.fastPessoaResponsavel
                      .datanascimento_abertura,
                    sexo: this.$store.state.fastPessoaResponsavel.sexo
                      ? this.$store.state.fastPessoaResponsavel.sexo
                      : null,
                    id_plataforma: this.id_plataforma
                  },
                  fast_usuario: {
                    email: this.$store.state.fastPessoaResponsavel.email_responsavel,
                    first_name: this.$store.state.fastPessoaResponsavel.nome_responsavel.split(
                      " "
                    )[0],
                    last_name: this.$store.state.fastPessoaResponsavel.nome_responsavel
                      .split(" ")
                      .shift(),
                  },
                  fast_pessoa_endereco: {
                    uf: this.$store.state.fastPessoaResponsavel.uf,
                    cep: this.$store.state.fastPessoaResponsavel.cep,
                    logradouro: this.$store.state.fastPessoaResponsavel.logradouro,
                    bairro: this.$store.state.fastPessoaResponsavel.bairro,
                    cidade: this.$store.state.fastPessoaResponsavel.cidade,
                    complemento: this.$store.state.fastPessoaResponsavel.complemento,
                    numero: this.$store.state.fastPessoaResponsavel.numero,
                  },
                  fast_pessoa_telefone: {
                    telefone: this.$store.state.fastPessoaResponsavel.celular
                      ? this.$store.state.fastPessoaResponsavel.celular
                      : null,
                  },
                  fast_plataforma_aluno_responsavel: {
                    id_plataforma: this.id_plataforma,
                    id_pessoa_aluno: this.id_pessoa_aluno,
                    id_pessoa_responsavel: this.$store.state.fastPessoaResponsavel.id_pessoa_responsavel
                      ? this.$store.state.fastPessoaResponsavel.id_pessoa_responsavel
                      : 0,
                    fone_comercial: this.$store.state.fastPessoaResponsavel.fone_comercial
                      ? this.$store.state.fastPessoaResponsavel.fone_comercial
                      : null,
                    celular: this.$store.state.fastPessoaResponsavel.celular
                      ? this.$store.state.fastPessoaResponsavel.celular
                      : null,
                    email_responsavel: this.$store.state.fastPessoaResponsavel
                      .email_responsavel
                      ? this.$store.state.fastPessoaResponsavel.email_responsavel
                      : null,
                    parentesco: this.$store.state.fastPessoaResponsavel.parentesco
                      ? this.$store.state.fastPessoaResponsavel.parentesco
                      : null,
                    responsavel_financeiro: this.$store.state.fastPessoaResponsavel
                      .responsavel_financeiro,
                    nome_responsavel: this.$store.state.fastPessoaResponsavel
                      .nome_responsavel
                      ? this.$store.state.fastPessoaResponsavel.nome_responsavel
                      : null,
                  },
                };
                this.promiseInserirFastApi(
                  modelCadastroUsuarioResponsavel,
                  "fast_plataforma_aluno_responsavel"
                )
                  .then((res) => {
                    this.$store.state.fastCarregando = false;
                    if (res.length) {
                      this.getFastPlataformaAlunoResponsavel(this.id_plataforma, this.id_pessoa_aluno)
                      this.exibeToasty("Salvo com sucesso", "success");
                      this.hideModal("modalCadastrarAlunoResponsavel");
                      this.$store.state.fastPessoaResponsavel = {
                        id_pessoa_aluno: 0,
                        id_plataforma: 0,
                        id_pessoa_responsavel: "",
                        fone_comercial: "",
                        celular: "",
                        email_responsavel: "",
                        parentesco: "",
                        responsavel_financeiro: false,
                        nome_responsavel: "",
                        datanascimento_abertura: null,
                        cpf_cnpj: "",
                        sexo: "",
                        cep: "",
                        logradouro: "",
                        numero: "",
                        complemento: "",
                        bairro: "",
                        cidade: "",
                        uf: "",
                        carregando: true,
                        alteracao_pendente: false,
                      };
                    } else {
                      this.exibeToasty("Erro ao salvar", "error");
                    }
                  })
                  .catch((e) => {
                    this.$store.state.fastCarregando = false;
                    this.exibeToasty(
                      "Erro ao salvar. Verifique se o email já não foi cadastrado por outro responsável.",
                      "error"
                    );
                  });
              
              }  

            }).catch(e => {         
              this.exibeToasty("Erro ao verificar email", "error")
            })
          }
        })
        .catch(e => {
          this.$store.state.fastCarregando = false
          this.exibeToasty(e, "error")
        })  
      },
      // Busca cep api correio
    async buscaCEPResponsavel(tipo){
      const cep = tipo == 'novo' ? this.$store.state.fastPessoaResponsavel.cep : this.$store.state.fastPessoaResponsavel.cep
      if (cep.length == 9) {
        this.buscaCEPCorreio(cep).then(e => {
          if (e.cep){
            if (tipo == 'novo') {
              this.$store.state.fastPessoaResponsavel.cep = e.cep;
              this.$store.state.fastPessoaResponsavel.logradouro = e.logradouro;
              this.$store.state.fastPessoaResponsavel.bairro = e.bairro;
              this.$store.state.fastPessoaResponsavel.cidade = e.cidade;
              this.$store.state.fastPessoaResponsavel.uf = e.uf;
            } else {
              this.$store.state.fastPessoaResponsavelEditar.cep = e.cep;
              this.$store.state.fastPessoaResponsavelEditar.logradouro = e.logradouro;
              this.$store.state.fastPessoaResponsavelEditar.bairro = e.bairro;
              this.$store.state.fastPessoaResponsavelEditar.cidade = e.cidade;
              this.$store.state.fastPessoaResponsavelEditar.uf = e.uf;
            }
          } else {
            this.exibeToasty("CEP não encontrado", "error")
          }
        }).catch(e => this.exibeToasty(e, "error"))
      }  
    },
    // Responsáveis
    async getFastPlataformaAlunoResponsavel(id_plataforma, id_pessoa_aluno) {
      this.$store.state.fastPessoasResponsaveis.loading = true;
      this.promiseGetFastApi(
        "api/fast_plataforma_aluno_responsavel/lista",
        "id_plataforma=" + id_plataforma + "&id_pessoa_aluno=" + id_pessoa_aluno
      )
        .then((obj) => {
          this.$store.state.fastPessoasResponsaveis.loading = false;
          if (obj.length) { 
            this.$store.state.fastPessoasResponsaveis.lista = obj;
          }
          else {
            this.$store.state.fastPessoasResponsaveis.lista = [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },    
    exibeModalEditarAlunoResponsavel(responsavel) {
      this.$store.state.fastCarregando = true;
      this.promiseGetFastApi(
        "api/fast_plataforma_usuario/busca_pessoa_secretaria",
        "id_pessoa=" + responsavel.id_pessoa_responsavel + "&id_plataforma=" + this.id_plataforma
      )
        .then((obj) => {
          let objPessoa = JSON.parse(obj);
          if (objPessoa.id_pessoa) {
            (responsavel.datanascimento_abertura = objPessoa.datanascimento_abertura
              ? objPessoa.datanascimento_abertura.split("T")[0]
              : ""),
              (responsavel.cpf_cnpj = objPessoa.cpf_cnpj),
              (responsavel.sexo = objPessoa.sexo),
              (responsavel.cep = objPessoa.cep),
              (responsavel.logradouro = objPessoa.logradouro),
              (responsavel.numero = objPessoa.numero),
              (responsavel.complemento = objPessoa.complemento),
              (responsavel.bairro = objPessoa.bairro),
              (responsavel.cidade = objPessoa.cidade),
              (responsavel.uf = objPessoa.uf),
              (responsavel.carregando = true),
              (responsavel.alteracao_pendente = false);
              this.$store.state.fastPessoaResponsavelEditar = responsavel;
              this.showModal("modalEditarAlunoResponsavel");
              this.$store.state.fastCarregando = false;
          } else {
            this.exibeToasty("Erro ao buscar pessoa", "error");
            this.$store.state.fastCarregando = false;
          }
        })
        .catch((e) => {
          console.log(e);
          this.$store.state.fastCarregando = false;
        });
    },
    async atualizaAlunoResponsavel() {
      this.getFastHoraServidor()
      // Validação
      .then(() => {
        let erros = [];
        if (!this.$store.state.fastPessoaResponsavelEditar.email_responsavel)
          erros.push("O email é obrigatório");
        if (!this.$store.state.fastPessoaResponsavelEditar.celular)
          erros.push("O telefone é obrigatório");
        if (!this.$store.state.fastPessoaResponsavelEditar.nome_responsavel)
          erros.push("O nome é obrigatório");
        else if (
          this.$store.state.fastPessoaResponsavelEditar.nome_responsavel.split(" ")
            .length < 2
        )
          erros.push("O sobrenome é obrigatório");
        if (!this.$store.state.fastPessoaResponsavelEditar.cpf_cnpj)
          erros.push("O CPF/CPNJ é obrigatório");
        if (!this.$store.state.fastPessoaResponsavelEditar.uf)
          erros.push("O estado é obrigatório");
        if (!this.$store.state.fastPessoaResponsavelEditar.cep)
          erros.push("O cep é obrigatório");
        if (!this.$store.state.fastPessoaResponsavelEditar.logradouro)
          erros.push("O endereço é obrigatório");
        if (!this.$store.state.fastPessoaResponsavelEditar.bairro)
          erros.push("O bairro é obrigatório");
        if (!this.$store.state.fastPessoaResponsavelEditar.cidade)
          erros.push("O cidade é obrigatório");
        if (!this.$store.state.fastPessoaResponsavelEditar.numero)
          erros.push("O número é obrigatório");
        if (!this.$store.state.fastPessoaResponsavelEditar.datanascimento_abertura)
          erros.push("A data de nascimento é obrigatória");
        
        // if (this.$store.state.fastPessoaResponsavelEditar.celular) {
        //   if (!this.validaTelefoneFormatado(this.$store.state.fastPessoaResponsavelEditar.celular)) erros.push("O telefone está no formato incorreto")
        // } 
        // if (this.$store.state.fastPessoaResponsavelEditar.fone_comercial) {
        //   if (!this.validaTelefoneFormatado(this.$store.state.fastPessoaResponsavelEditar.fone_comercial)) erros.push("O telefone comercial está no formato incorreto")
        // } 
        if (this.$store.state.fastPessoaResponsavelEditar.datanascimento_abertura) {
          let objDataNasc = new Date(`${this.$store.state.fastPessoaResponsavelEditar.datanascimento_abertura}T00:00:00`)
          let cincoAnosAtras = new Date(this.$store.state.fastDataAtualServidor.setFullYear(this.$store.state.fastDataAtualServidor.getFullYear() - 5))
          if (objDataNasc > cincoAnosAtras) erros.push("A data de nascimento deve ser superior a 5 anos atrás")
        }
        if (this.$store.state.fastPessoaResponsavelEditar.cpf_cnpj) {
          if (!this.validaCpfCnpj(this.$store.state.fastPessoaResponsavelEditar.cpf_cnpj)) erros.push("CPF/CNPJ inválido")
        }      
        if (this.$store.state.fastPessoaResponsavelEditar.cep) {
          if (!/^[0-9]{8}$/.test(this.$store.state.fastPessoaResponsavelEditar.cep.replace("-", ""))) erros.push("CEP inválido")
        }   
        return erros
      })
      .then(erros => {
        if (!erros.length) {
        const modelCadastroUsuarioResponsavel = {
          fast_pessoa: {
            nome_razao: this.$store.state.fastPessoaResponsavelEditar
              .nome_responsavel,
            cpf_cnpj: this.$store.state.fastPessoaResponsavelEditar.cpf_cnpj,
            datanascimento_abertura: this.$store.state.fastPessoaResponsavelEditar
              .datanascimento_abertura,
            sexo: this.$store.state.fastPessoaResponsavelEditar.sexo
              ? this.$store.state.fastPessoaResponsavelEditar.sexo
              : null,
              id_plataforma: this.id_plataforma
          },
          fast_usuario: {
            email: this.$store.state.fastPessoaResponsavelEditar.email_responsavel,
            first_name: this.$store.state.fastPessoaResponsavelEditar.nome_responsavel.split(
              " "
            )[0],
            last_name: this.$store.state.fastPessoaResponsavelEditar.nome_responsavel
              .split(" ")
              .shift(),
          },
          fast_pessoa_endereco: {
            uf: this.$store.state.fastPessoaResponsavelEditar.uf,
            cep: this.$store.state.fastPessoaResponsavelEditar.cep,
            logradouro: this.$store.state.fastPessoaResponsavelEditar.logradouro,
            bairro: this.$store.state.fastPessoaResponsavelEditar.bairro,
            cidade: this.$store.state.fastPessoaResponsavelEditar.cidade,
            complemento: this.$store.state.fastPessoaResponsavelEditar.complemento,
            numero: this.$store.state.fastPessoaResponsavelEditar.numero,
          },
          fast_pessoa_telefone: {
            telefone: this.$store.state.fastPessoaResponsavelEditar.celular
              ? this.$store.state.fastPessoaResponsavelEditar.celular
              : null,
          },
          fast_plataforma_aluno_responsavel: {
            id_plataforma: this.id_plataforma,
            id_pessoa_aluno: this.id_pessoa_aluno,
            id_pessoa_responsavel: this.$store.state.fastPessoaResponsavelEditar
              .id_pessoa_responsavel
              ? this.$store.state.fastPessoaResponsavelEditar.id_pessoa_responsavel
              : null,
            fone_comercial: this.$store.state.fastPessoaResponsavelEditar
              .fone_comercial
              ? this.$store.state.fastPessoaResponsavelEditar.fone_comercial
              : null,
            celular: this.$store.state.fastPessoaResponsavelEditar.celular
              ? this.$store.state.fastPessoaResponsavelEditar.celular
              : null,
            email_responsavel: this.$store.state.fastPessoaResponsavelEditar
              .email_responsavel
              ? this.$store.state.fastPessoaResponsavelEditar.email_responsavel
              : null,
            parentesco: this.$store.state.fastPessoaResponsavelEditar.parentesco
              ? this.$store.state.fastPessoaResponsavelEditar.parentesco
              : null,
            responsavel_financeiro: this.$store.state.fastPessoaResponsavelEditar
              .responsavel_financeiro,
            nome_responsavel: this.$store.state.fastPessoaResponsavelEditar
              .nome_responsavel
              ? this.$store.state.fastPessoaResponsavelEditar.nome_responsavel
              : null,
          },
        };

        this.$store.state.fastCarregando = true
        this.promiseAtualizarFastApi(
          modelCadastroUsuarioResponsavel,
          "fast_plataforma_aluno_responsavel"
        )
          .then((e) => {
            this.getFastPlataformaAlunoResponsavel(this.id_plataforma, this.id_pessoa_aluno)
            this.$store.state.fastCarregando = false
            this.exibeToasty("Atualizado com sucesso", "success");
            this.hideModal("modalEditarAlunoResponsavel");
            this.$store.state.fastPessoaResponsavelEditar = {
              id_pessoa_aluno: 0,
              id_plataforma: 0,
              id_pessoa_responsavel: "",
              fone_comercial: "",
              celular: "",
              email_responsavel: "",
              parentesco: "",
              responsavel_financeiro: false,
              nome_responsavel: "",
              datanascimento_abertura: null,
              cpf_cnpj: "",
              sexo: "",
              cep: "",
              logradouro: "",
              numero: "",
              complemento: "",
              bairro: "",
              cidade: "",
              uf: "",
              alteracao_pendente: false,
            };
            
          })
          .catch((e) => {
            this.exibeToasty("Erro ao atualizar", "error");
            this.$store.state.fastCarregando = false
          });
        } else {
          erros.forEach((e) => this.exibeToasty(e, "error"));
          this.$store.state.fastCarregando = false
        }
      })
      .catch(e => {
          this.$store.state.fastCarregando = false
          this.exibeToasty(e, "error")
        }) 
    },
    exibeModalExcluirAlunoResponsavel(responsavel) {
      this.$store.state.fastPessoaResponsavelEditar = responsavel;
      this.showModal("modalExcluirAlunoResponsavel");
    },
    async excluirAlunoResponsavel() {
      this.promiseExcluirFastApi(
        this.$store.state.fastPessoaResponsavelEditar,
        "fast_plataforma_aluno_responsavel"
      )
        .then((e) => {
          this.exibeToasty("Excluído com sucesso", "success");
          this.getFastPlataformaAlunoResponsavel(
            this.id_plataforma,
            this.id_pessoa_aluno
          );
          this.hideModal("modalExcluirAlunoResponsavel");
        })
        .catch((e) => {
          this.exibeToasty("Erro ao excluir", "error");
          this.$store.state.fastCarregando = false;
        });
    },
  }
}
</script>
<style scoped>
</style>